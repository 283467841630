import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row, Col, Card } from 'react-bootstrap';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { AshTrace } from '../../../modules/ash/components/ash-trace';

const AshGuidesSYPBaili: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should You Pull Baili Tusu?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_baili.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should You Pull Baili Tusu?</h1>
          <h2>
            A dedicated guide for Baili Tusu that will help you decide whether
            to pull her or not.
          </h2>
          <p>
            Last updated: <strong>30/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Introduction" />
              <p>
                <strong>
                  Baili Tusu is one of the core Echomancers of Fire teams, along
                  with Cyros.
                </strong>{' '}
                Both have fantastic synergy together, and are able to capitalize
                in every source possible of Crit Rate, Crit DMG, Extra DMG and
                Survivability from Square Traces. This makes the choice of
                getting Baili Tusu ideal for those who love an extra aggressive
                approach, specialized in Challenge stages where the number of
                Elite enemies and enemies with block gauges are very high. Tusu
                also works well as a general breaker to have in your roster,
                thanks to his surprising survivability, high block gauge
                capability and arguably the biggest range among the current
                Strikers.
              </p>
              <p>
                After using his ultimate, he’s also capable of clearing large
                areas of airborne enemies, which is another differential in his
                already unique kit. However, it must be warned that Tusu may not
                be for everyone. He requires more manual input than most
                characters, and making the most of his kit can be tricky,
                especially if you try to use him outside a Fire team. Despite
                that, much like most Echomancers in Ash Echoes, if you like his
                style, you’re in for a good time.
              </p>
              <StaticImage
                src="../../../images/ash/pull/baili_1.webp"
                alt="Guides"
              />
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Review" />
              <p>
                The main character of the first Gujian game, Baili Tusu joins
                the universe of Ash Echoes with a Sci-Fi approach and a kit that
                still reflects well his story in both games. His core mechanic
                revolves around sacrificing his own HP to gain ATK, DR and
                shields, making him surprisingly tanky. He can also passively
                gain CRIT Rate, CRIT DMG and ignore a portion of the enemy’s DR,
                making him a formidable main carry capable of unleashing
                powerful bursts.
              </p>
              <p>
                Another interesting aspect of Baili Tusu is his clone, “Dream
                Incarnate”. If you ever wished to have the same character twice
                on the field or to have one of your characters in two places at
                the same time, then Baili Tusu is the Echomancer for you. His
                first skill, “Banish Evil”, has a very long and versatile range.
                By changing the positioning of Tusu and his clone, you can adapt
                the shape and area of effect, focus fire on a single location,
                or even expand it to make the range twice as long.
              </p>
              <StaticImage
                src="../../../images/ash/pull/baili_2.webp"
                alt="Guides"
              />
              <p>
                “Gaze Ablaze” is another intriguing skill. It has a far smaller
                AoE, but a large range and infinite uses under a 10s cooldown,
                allowing Baili Tusu to keep high mobility at all times. The
                third and final key piece of Tusu’s kit is his Seed Skill.
                Similarly to Scarlett, after the damage portion, it grants a
                buffed state that lasts 30s and enhance all other skills. It’s
                under this state, called “Rekindled”, that Baili Tusu is capable
                of destroying large waves of airborne enemies with “Banish
                Evil”. Meanwhile, Gaze Ablaze no longer consumes HP while under
                this state and breaks 4 Block Gauges at once.
              </p>
              <p>
                In order to master Baili Tusu’s gameplay and make the most of
                his potential, be sure to bring plenty of ATK and VIT to the
                build. For Tusu, VIT not only helps with his survivability, but
                his skills also scale partially with it. Thankfully, there are a
                lot of VIT Memory Traces and assorted Square-type skills that
                help both with survivability and DMG when the Echomancer has a
                shield or loses HP. This synergizes perfectly with Baili Tusu
                and his teams made mainly of Square-type fellow Echomancers.
              </p>
              <h5>Pros & Cons</h5>
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Extremely large and versatile range across all skills.
                        </li>
                        <li>
                          Skill 2 is spammable with infinite uses and doesn’t
                          cost HP when under the Rekindled effect.
                        </li>
                        <li>
                          One of the best at breaking gauges and barriers.
                        </li>
                        <li>
                          Rare case of a Striker with Anti-air capabilities.
                        </li>
                        <li>
                          Can easily trigger any shield-based Trace skills.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Requires a lot of manual input to be efficient.</li>
                        <li>
                          Anti-air relies on the 30s window after ultimate use,
                          and is only available through his first skill.
                        </li>
                        <li>
                          Overall output falls down a bit when used outside the
                          Fire Team.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Gameplay Tips" />
              <h5>Dupes</h5>
              <p>
                Baili Tusu works fine at 0 dupes. His best cost-benefit dupe is
                his first one, which gives him 15 stacks of Spark after the
                effect of Rekindled ends. For those who want to main the Pure
                Fire team, the third dupe is a worth investment, as it will be
                an easy +24% DMG passively for Tusu once Thea is added to the
                roster.
              </p>
              <h5>Skill Upgrade Priority</h5>
              <ul>
                <li>
                  Skill 1, “Banish Evil” should be the priority if you plan on
                  focusing Tusu’s setup for Challenge stages and bosses;
                </li>
                <li>
                  Skill 2, “Gaze Ablaze” should be the priority for general use,
                  especially if you’re using Baili Tusu on non-Pure Fire teams,
                  due to its spammable nature and AoE gauge breaking.
                </li>
              </ul>
              <StaticImage
                src="../../../images/ash/pull/baili_3.webp"
                alt="Guides"
              />
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Team Recommendation" />
              <h5>Team 1</h5>
              <p>
                Leader: <strong>Baili Tusu</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>FIRE TEAM - REKINDLED</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="baili-tusu"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="cyros"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="mo-hongxiu"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                This team has Baili Tusu as leader and focuses on having a
                strong frontline with maximum exploit of stacking Elemental RES
                debuffs, Extra DMG and CRITs. The ideal is to have Mo Hongxiu
                with Dupe 1 for the 20% Fire RES reduction, but if you don’t
                have that, she can still hold the line and prevent Baili Tusu
                and Cyros from taking unnecessary damage. This allows Freda to
                keep her Buff mode up more often. The choice for Tusu as leader
                here comes from his Leader skill, “Learning from Mistakes”. It
                can stack up to +75% Extra DMG permanently, being a more stable
                version than Cyros’ Leader skill if you’re focusing the main
                bulk of damage on Baili Tusu.
              </p>
              <p>
                <strong>Back-up Options:</strong>
                <AshCharacter
                  slug="yuqi"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="lydia"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="scarlett"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="felicio"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="reid"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="roar"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="su-xiao"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul>
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile /> or{' '}
                  <AshTrace name="Into A Paper Crane" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Codename Diversity" mode="data" onProfile />{' '}
                  (if you don’t have Mo Hongxiu, use Roar to trigger this one)
                </li>
                <li>
                  <AshTrace name="Company" mode="data" onProfile /> or{' '}
                  <AshTrace name="Behind the Curtains" mode="data" onProfile />{' '}
                  or <AshTrace name="Sky Fall" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Festive Night" mode="data" onProfile /> or
                  <AshTrace name="Dusk Radiance" mode="data" onProfile /> (if
                  Dupe 1 Mo Hongxiu)
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Growth" mode="data" onProfile /> or{' '}
                  <AshTrace name="Karma" mode="data" onProfile /> or{' '}
                  <AshTrace name="Lightchaser" mode="data" onProfile />{' '}
                </li>
              </ul>
              <h5>Team 2</h5>
              <p>
                Leader: <strong>Cyros</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>A SONG OF MELT</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="cyros"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="baili-tusu"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="begonia"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Elemental Reaction</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="longqing"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                If you like living dangerously and like the idea of melting your
                enemies both through Elemental Reactions and literally, this
                team is for you. For this team, Begonia’s role goes beyond
                buffing ASPD: Ice DMG on Fire Zone (or vice-versa) trigger the
                elemental reaction “Melt”, which can stack to up to a reduction
                of 60% DR on affected enemies. Add this to consistent crits and
                explosive bursts, and your enemies will melt in no time.
                Longqing is the ideal choice here to stack Inspire faster as
                well as to grant extra survivability through her barriers and
                more intensive healing.
              </p>
              <p>
                <strong>Back-up Options:</strong>
                <AshCharacter
                  slug="freda"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="zodivin-azure"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="scarlett"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="felicio"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="reid"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="roar"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="su-xiao"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="bellia"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul>
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile /> or{' '}
                  <AshTrace name="Into A Paper Crane" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Beneath the Deception"
                    mode="data"
                    onProfile
                  />{' '}
                  (Lv 1 Awakening Skill only) or
                  <AshTrace name="Growth" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Company" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Behind the Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Nightmare" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
              </ul>
              <h5>Team 3</h5>
              <p>
                Leader: <strong>Cyros</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>EXPLOSION TEAM</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="cyros"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Elemental Zone</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="lydia"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="baili-tusu"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="longqing"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                This team is built around the “Life Anthem” skill, which can
                grant lots of passive healing and insane amounts of MST to
                skyrocket Lydia’s overall DMG output. In addition, Cyros can
                provide large elemental zones to guarantee even more damage
                through the Explosion elemental reaction, while Baili Tusu helps
                split the frontline and trigger certain Trace skills through his
                core mechanics.
              </p>
              <p>
                <strong>Back-up Options:</strong> Any Square-type Echomancer
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul>
                <li>
                  <AshTrace name="Starlit Memories" mode="data" onProfile />{' '}
                  [CORE] (Pick the Nexus Skill “Life Anthem” at least at level 2
                  and fill all your backup slots with Square-type Echomancers.
                  They don’t need to be built.)
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile /> or{' '}
                  <AshTrace name="Into A Paper Crane" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Company" mode="data" onProfile />
                </li>
                <li>
                  <li>
                    <AshTrace
                      name="Behind the Curtains"
                      mode="data"
                      onProfile
                    />{' '}
                    or <AshTrace name="Festive Night" mode="data" onProfile />
                  </li>
                  <AshTrace name="Nightmare" mode="data" onProfile /> or
                  <AshTrace name="Elegy At Dusk" mode="data" onProfile /> (if
                  using Freda instead of Longqing)
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Team Recommendation (Beginner/Budget version)" />
              <h5>Team 1</h5>
              <p>
                Leader: <strong>Baili Tusu</strong>
              </p>
              <div className={`character-teams box Fire`}>
                <div className="team-header">
                  <p>BUDGET PURE FIRE</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="baili-tusu"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="reid"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Elemental Zone</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="roar"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="su-xiao"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                An option for new players who want to dip their toes on the
                experience of the Pure Fire team. Except for Baili Tusu, all
                characters here can be obtained for free. This team is also very
                forgiving, since Reid can revive himself once per battle, and Su
                Xiao can revive one ally per battle. Roar is a versatile DPS who
                should not be underestimated, being able to switch attack modes
                to adapt and charge Inspire stacks through “Codename Diversity”
                by herself. Finally, Su Xiao’s ultimate increases the team’s Max
                HP, adding extra leeway for Baili Tusu’s mechanics for free.
              </p>
              <p>
                <strong>Back-up Options:</strong>
                <AshCharacter
                  slug="freda"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="bellia"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                (can trigger Melt reliably and add extra CC),
                <AshCharacter
                  slug="felicio"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                ,
                <AshCharacter
                  slug="ms-lew"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </p>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul>
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Codename Diversity" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Into A Paper Crane" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Festive Night" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Behind the Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Future Synergies" />
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="boreas"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                <strong>[Buffer/Support]</strong> - Boreas fills in the support
                role for the Melt team. With his extra shields, healing, +50%
                Crit and Ice Attack Enhancements, he fits the team like a glove.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="feng-qingxue"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                <strong>[DPS]</strong> - As yet another premier DPS, Feng
                Qingxue replaces Begonia in the Melt team later on, adding even
                more burst damage with her unlimited skill uses.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="thea"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                <strong>[DPS]</strong>- Thea ensures that you’ll never have to
                worry about anti-air issues with the pure fire team ever again.
                She brings a lot of utility in the form of Stuns, even more Fire
                Zones, 30% Fire Res reduction on enemies and, more importantly,
                crucial survivability. By rewinding time, Thea can bring back
                all the HP Baili Tusu sacrificed, while allowing him to keep his
                buffs and shields. Definitely a must-have to bring the Fire Team
                to the next level.
              </p>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h5>Jump to</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Review</a>
                </li>
                <li>
                  <a href="#section-2">Gameplay Tips</a>
                </li>
                <li>
                  <a href="#section-3">Team Recommendation</a>
                </li>
                <li>
                  <a href="#section-4">
                    Team Recommendation (Beginner/Budget version)
                  </a>
                </li>
                <li>
                  <a href="#section-5">Future Synergies</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesSYPBaili;

export const Head: React.FC = () => (
  <Seo
    title="Should You Pull Baili Tusu | Ash Echoes | Prydwen Institute"
    description="A dedicated guide for Baili Tusu that will help you decide whether to pull her or not."
    game="ash"
  />
);
